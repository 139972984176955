<template>
  <div class="layout">
    <div class="classify-layout">
      <!-- 第一级 -->
      <ul class="one-ul-layout">
        <li
          class="one-ul-layout-li"
          v-for="(item, index) in dataList"
          :class="{ 'is-show-color': active === index }"
          :key="index"
          @click="onClick(index, item)"
        >
          <div class="mark" :class="{ 'is-show-mark': active === index }"></div>
          <span>{{ item.class_name }}</span>
        </li>
      </ul>
      <!-- 第二级 -->
      <ul class="two-ul-layout">
        <li
          class="two-ul-li"
          v-for="(item, index) in twoList"
          :key="index"
          :class="{ 'is-show-mark': active === index }"
        >
          <div class="two-ul-title">{{ item.class_name }}</div>
          <!-- 第三级 -->
          <ul class="three-ul-layout">
            <li
              class="three-ul-li"
              :class="{ 'font-color': active1 === item.id }"
              v-for="(item, i) in item.child_class"
              :key="i"
              @click="onColor(item)"
            >
              {{ item.class_name }}
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { GoodsClass } from "@/api/goods";

export default {
  name: "classify",
  props: {
    classType: {
      type: String,
      default: "1",
    },
  },
  components: {},
  data() {
    return {
      dataList: [],
      twoList: [],
      listTree: [1, 2, 3],
      active: 0,
      active1: 0,
    };
  },
  mounted() {
    this.getGoodsClass();
  },
  methods: {
    onClick(index, item) {
      this.active = index;
      this.twoList = this.dataList[index].child_class;
      if (item !== undefined && item.id === 0) {
        this.$emit("setClass", item);
      }
    },
    onColor(item) {
      this.active1 = item.id;
      this.$emit("setClass", item);
    },
    async getGoodsClass() {
      const res = await GoodsClass({ type: this.classType });
      if (res.code * 1 === 1) {
        res.data.unshift({
          class_name: "全部",
          id: 0,
        });
        this.dataList = res.data;
        this.onClick(0);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.layout {
  padding: 0 0 58px 0;
}
.classify-layout {
  display: flex;
  justify-content: space-between;
}
.one-ul-layout {
  .one-ul-layout-li {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 104px;
    height: 58px;
    font-size: 16px;
    text-align: center;
    box-sizing: border-box;
    .mark {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      display: none;
      width: 6px;
      height: 22px;
      background-color: #ed301d;
    }
    .is-show-mark {
      display: block;
    }
  }
  .is-show-color {
    color: #ed301d;
  }
}
.two-ul-layout {
  position: absolute;
  right: 0;
  top: 0;
  width: 310px;
  padding: 12px 12px 70px 12px;
  box-sizing: border-box;
  .two-ul-li {
    margin-bottom: 12px;
    padding: 12px;
    border-radius: 15px;
    background-color: #fff;
    .two-ul-title {
      font-size: 16px;
    }
  }
}
.three-ul-layout {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  .three-ul-li {
    min-width: 78px;
    height: 28px;
    background: #f2f2f2;
    border-radius: 99px;
    text-align: center;
    line-height: 28px;
    margin-top: 12px;
    margin-right: 16px;
    font-size: 14px;
  }
  .font-color {
    color: #ed301d;
  }
}
</style>
